import { useEffect, useState } from "react";
import DashboardVideoCard from "../components/dashboard-video/dashboard-video-card";
import { GetData } from "../hooks/fetchdata";
import "./home-videos.scss";

const HomeVideos = () => {
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("barchasi");
  const [sortOrder, setSortOrder] = useState("");

  const getCategories = () => {
    GetData(`/api/category/`)
      .then((data) => {
        setCategories(data);
        setActiveCategory("barchasi");
        if (data.length > 0) {
          const allCourses = data.flatMap((category) => category.courses);
          setCourses(allCourses);
        }
      })
      .catch((err) => console.error(err));
  };

  const updateCourses = (id) => {
    if (id === "all") {
      GetData(`/api/category/`)
        .then((data) => {
          const allCourses = data.flatMap((category) => category.courses);
          const sortedCourses = sortCourses(allCourses, sortOrder);
          setCourses(sortedCourses);
        })
        .catch((err) => console.error(err));
    } else {
      GetData(`/api/category/${id}/`)
        .then((data) => {
          const sortedCourses = sortCourses(data.courses, sortOrder);
          setCourses(sortedCourses);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleActiveCategory = () => {
    setActiveCategory("barchasi");
    updateCourses("all");
  };

  const handleCourseById = (id, name) => {
    setActiveCategory(name);
    updateCourses(id);
  };

  const sortCourses = (courses, order) => {
    if (order === "id") {
      return [...courses].sort((a, b) => a.id - b.id);
    } else if (order === "date") {
      return [...courses].sort((a, b) => b.id - a.id);
    } else if (order === "enrolment") {
      return [...courses].sort((a, b) => b.enrolment - a.enrolment);
    } else if (order === "name") {
      return [...courses].sort((a, b) => a.title.localeCompare(b.title));
    }
    return courses;
  };

  const handleSortChange = (e) => {
    const order = e.target.value;
    setSortOrder(order);
    const sortedCourses = sortCourses(courses, order);
    setCourses(sortedCourses);
  };

  return (
    <div className="video container">
      <div className="category-sidebar">
      <h5 id="department">Bo'limlar</h5>
        <div
          onClick={handleActiveCategory}
          className={activeCategory === "barchasi" ? "category-card active center" : "category-card center"}
        >
          <label htmlFor="check-all" className="form-control active">
            <input
              type="checkbox"
              name="checkbox"
              id="check-all"
              checked={activeCategory === "barchasi"}
              onChange={handleActiveCategory}
            />
            <span>Hamma kurslar</span>
            <span className="custom-checkbox"></span>
          </label>
        </div>

        {categories.length > 0 ? (
          categories.map((item) => (
            <div
              key={item.id}
              onClick={() => handleCourseById(item.id, item.title)}
              className={
                activeCategory === item.title
                  ? "category-card active center"
                  : "category-card center"
              }
            >
              <label htmlFor={`check-${item.id}`} className="form-control active">
                <input
                  type="checkbox"
                  name="checkbox"
                  id={`check-${item.id}`}
                  checked={activeCategory === item.title}
                  onChange={() => handleCourseById(item.id, item.title)}
                />
                <span>{item.title}</span>
                <span className="custom-checkbox"></span>
              </label>
            </div>
          ))
        ) : (
          <span>Malumotlar yuklanmoqda</span>
        )}
      </div>

      <div className="helperContainer">
        {courses && courses.length > 0 ? (
          <div className="videosNavbar">
            <h5>Kurslar soni: {courses.length} ta</h5>
            <div>
              <select name="" id="select_form" onChange={handleSortChange}>
                <option value="">Saralash</option>
                <option value="date">Sana bo'yicha filtrlash</option>
                <option value="name">Nomi bo'yicha filtrlash</option>
              </select>
            </div>
          </div>
        ) : (
          <h2></h2>
        )}
        <div className="videos load-anim">
          {courses && courses.length > 0 ? (
            courses.map((item) => (
              <DashboardVideoCard data={item} key={item.id} />
            ))
          ) : (
            <h2>Malumotlar yuklanmoqda</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeVideos;

